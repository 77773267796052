
import Vue from 'vue'
import ViewsTable from '@/components/ViewsTable.vue'

import { mapState } from 'vuex'
import { VIEWS_GET } from '@/store/actions/views.ts'

export default Vue.extend({
    name: 'Views',
    components: {
        ViewsTable,
    },
    data() {
        return {
            refresh_views_id: -1,
            errors: [],
            success: null,
        }
    },
    methods: {
        loadViews() {
            return new Promise<void>((resolve, reject) => {
                this.$store.dispatch(VIEWS_GET)
                    .then(() => { resolve() })
                    .catch((err) => { reject(err) })
            })
        },
    },
    mounted: function () {
        this.loadViews()

        // Reload the subscriptions every 30 seconds
        let that: any = this
        this.refresh_views_id = setInterval(async function () {
            await that.loadViews()
        }.bind(that), 30000)
    },
    destroyed() {
        clearInterval(this.refresh_views_id)
    },
    computed: {
        ...mapState({
            views: (state: any) => state.views.views
        })
    }
})
