
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { VIEWS_MARK } from '@/store/actions/views.ts'
import { ViewsItem } from '@/store/modules/views.ts'

export default Vue.extend({
    name: 'ViewsTable',
    data() {
        return {
            filter: '',
            view: {},
            fields: ['ip', 'visits',
                {
                    key: 'last_visit',
                    formatter: (value: number) => {
                        return (this as any).parseDate(value)
                    }
                },
                'mark'],
            mounted: false,
            total_visits: 0,
        }
    },
    props: {
        views: {type: Object },
    },
    methods: {
        parseDate(timestamp: number) {
            var date = new Date(timestamp)
            return date.toLocaleDateString('nl') + ' @ ' + date.toLocaleTimeString('nl')
        },
        setMark(view: ViewsItem, mark: any) {
            view.mark = mark
            this.$store.dispatch(VIEWS_MARK, { data: view })
        },
    },
    mounted: function() {
        this.mounted = true
    },
    computed: {
        viewsList: function() {
            return Object.entries(this.views).map(function (entry) {
                let visits = (entry[1] as any).timestamps.length
                let last_visit = (entry[1] as any).timestamps[visits - 1]
                return Object.assign({ ip: entry[0] }, entry[1], { 'visits': visits, 'last_visit': last_visit })
            })
        },
        totalViews() {
            return Object.values(this.views).reduce((sum, entry) => {
                return sum + (entry as any).timestamps.length
            }, 0)
        }
    },
})
